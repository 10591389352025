<template>
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="opacity-0"
    leave-class="opacity-100"
    appear
  >
    <div
      class="fixed z-10 top-0 left-0 w-screen h-screen flex justify-center"
      style="background: rgba(22, 23, 34, 0.6)"
      v-if="isOpen"
    ></div>
  </transition>
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="top-1/2 opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="top-1/2 opacity-0"
    leave-class="top-0 opacity-100"
    appear
  >
    <div
      class="bg-bg-primary z-10 fixed left-0 right-0 top-0 bottom-0 overflow-y-scroll m-max-width mx-auto pb-10"
      v-if="isOpen"
    >
      <div class="pl-6 pt-4 h-10">
        <button
          class="
            text-4xl
            h-5
            focus:outline-none
            overflow-hidden
            flex
            items-center
          "
          @click="$emit('close')"
        >
          &times;
        </button>
      </div>
      <div class="px-6 pb-8">
        <h4 class="font-semibold text-lg py-3 flex justify-between">
          <span class="capitalize">{{ pledgeType }} Pledgers</span>
          <span>({{ pledgeResponse.data?.length }})</span>
        </h4>
        <BPageLoader v-if="pledgeResponse.isLoading" />
        <template v-else-if="pledgeResponse.data?.length">
          <BBlessor
            v-for="blessor in pledgeResponse.data"
            :key="blessor.id"
            :blessor="blessor"
            :isPledge="true"
          />
        </template>
        <div class="flex pt-8 flex-col items-center justify-center" v-else>
          <span class="p-6 bg-bg-alternate-3 rounded-full">
            <ResultEmptyIcon class="fill-current h-10" />
          </span>
          <h4 class="font-medium capitalize text-md mt-8">
            No {{ pledgeType }} Pledger
          </h4>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BBlessor from '@/components/ui/BBlessor';
import { watch, onBeforeUnmount } from 'vue';

export default {
  name: 'BlessorModal',

  components: { BBlessor },

  emits: ['close'],

  props: ['isOpen', 'pledgeType', 'pledgeResponse'],

  setup(props) {
    onBeforeUnmount(() => {
      document.body.classList.remove('modal-open');
    });

    watch(props, ({ isOpen }) => {
      if (isOpen) document.body.classList.add('modal-open');
      else document.body.classList.remove('modal-open');
    });
  },
};
</script>
