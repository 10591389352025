<template>
  <BPageLoader v-if="isLoading" />
  <div class="relative" v-else>
    <div
      v-if="successModal"
      class="text-center w-full lg:hidden fixed h-screen bg-bg-alternate-3"
      style="z-index: 9999999999999999 !important"
    >
      <div class="flex flex-col h-full items-center justify-center">
        <successIcon />
        <p class="font-semibold mb-5 text-lg mt-8 text-text-alternate-1">
          Congratulations
        </p>
        <p class="text-sm text-text-primary">Your withdrawal was successful</p>
      </div>
    </div>
   
    <div class=" lg:flex justify-center lg:pt-4">
      <div class="b-card lg:mt-24 lg:pt-8 lg:px-26">
        <div
          class="
            h-full
            p-6
            flex-grow flex flex-col
            text-text-primary
            justify-center
          "
          v-if="delResponse.isSuccess"
        >
          <div
            class="text-center pb-6 flex flex-col items-center justify-center"
          >
            <SuccessIcon class="mb-8 mt-4 mx-auto" />
            <h4 class="font-semibold text-lg">Fundraiser Deleted</h4>
          </div>
          <router-link
            class="
              bg-btn-bg-primary
              max-w-sm
              text-center
              mx-auto
              inline-block
              text-btn-text-primary
              rounded
              w-full
              mt-8
              py-4
              font-bold
              text-sm
              xs:text-base
              focus:outline-none
            "
            :to="{ name: 'CreateProject' }"
          >
            Create New Fundraiser
          </router-link>
        </div>
        <div class="py-2 flex flex-col text-text-primary" v-else>
          <header class="px-6 py-2.5 text-lg flex text-text-alternate-1">
            <button class="focus:outline-none" @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto px-3 font-semibold truncate">
              {{ data.title }}
            </h2>
            <button class="focus:outline-none" @click="showPickMenu = true">
              <DotMenuIcon class="h-6 fill-current text-text-primary" />
            </button>
          </header>
          <main>
            <section class="px-6">
              <div
                class="
                  block
                  rounded
                  bg-bg-alternate-8
                  p-4
                  sm:py-6
                  mt-4
                  text-text-secondary
                "
              >
                <h5 class="font-medium text-xs text-text-alternate-9">
                  BALANCE
                </h5>
                <p class="font-bold text-2xl pt-2">
                  ₦ {{ data.wallet.balance.toLocaleString() }}
                </p>
              </div>
              <p class="flex justify-between mb-4 mt-2 text-xs">
                <span class="text-text-alternate-3 font-medium"
                  >Next Payout:</span
                >
                <span class="text-text-alternate-1 font-semibold">
                  {{ getTime() }}
                </span>
              </p>

              <button
                v-if="data.manualWithdrawal"
                @click="checkWithdrawalDueDate()"
                :disabled="data.wallet.balance >= 1000 ? false : true"
                class="
                  h-10
                  border
                  w-full
                  border-btn-border-secondary
                  rounded
                  text-btn-text-secondary
                  font-bold
                "
              >
                Withdraw
              </button>
              <hr class="my-4 text-text-alternate-8" />
            </section>
            <section class="px-6 mt-4 mb-3">
              <div class="flex justify-between">
                <span class="font-semibold text-xl text-text-alternate-10">
                  ₦{{ sumOfBlessing }}
                </span>
                <button
                  class="
                    border
                    focus:outline-none
                    capitalize
                    rounded
                    px-2
                    py-1.5
                    font-semibold
                    text-text-alternate-1 text-2xs
                    border-border-primary
                    flex
                    items-center
                  "
                  @click="openState = true"
                >
                  {{ selected }}
                  <ChevronDownIcon :class="['fill-current h-4 pl-3']" />
                </button>
              </div>
              <p class="capitalize text-2xs text-text-alternate-3 font-medium">
                {{ selected !== 'today' ? `This ${selected}` : 'Today' }}
              </p>
              <!-- <BarChart :height="240" :dataset="dataset" :key="selected" /> -->
            </section>
            <section>
              <header class="overflow-x-auto bg-bg-alternate-3 px-6 mx-auto">
                <ul
                  class="inline-flex text-xs xs:text-sm justify-between w-full"
                >
                  <li v-for="(nav, index) in tabs" :key="index">
                    <button
                      :class="[
                        'px-4 py-3 font-semibold nav-border focus:outline-none',
                        active === index
                          ? 'nav-active text-text-alternate-1'
                          : 'text-text-alternate-10',
                      ]"
                      @click="active = index"
                    >
                      {{ nav }}
                    </button>
                  </li>
                </ul>
              </header>
              <div class="px-6" v-if="active === 0">
                <BPageLoader v-if="blessorsResponse.isLoading" />
                <div
                  class="
                    flex flex-grow flex-col
                    justify-center
                    items-center
                    py-6
                  "
                  v-else-if="!blessorsResponse.data.length"
                >
                  <span
                    class="
                      h-24
                      w-24
                      rounded-full
                      bg-bg-alternate-3
                      flex
                      items-center
                      justify-center
                      my-6
                    "
                  >
                    <CashIcon
                      class="h-8 stroke-2 stroke-current"
                      style="stroke: #314b63"
                    />
                  </span>
                  <h4 class="my-4 text-lg font-semibold">No Blessings</h4>
                  <p class="text-xs font-medium text-center">
                    Once you start receiving blessings, they will appear here.
                  </p>
                </div>
                <div class="text-text-alternate-1" v-else>
                  <p class="flex justify-between font-semibold mt-4 mb-2">
                    <span class="text-lg">History</span>
                    <button
                      class="focus:outline-none"
                      @click="toggleShowSearch"
                    >
                      <DiscoverIcon
                        class="h-5 stroke-2 stroke-current"
                        style="stroke: #7e7b7b"
                      />
                    </button>
                  </p>
                  <div v-if="showSearch">
                    <input
                      type="search"
                      class="
                        border border-border-primary
                        rounded
                        input-shadow
                        w-full
                        my-1
                        py-2
                        px-4
                        text-2xs
                        focus:outline-none
                      "
                      v-model="searchName"
                      placeholder="Search"
                    />
                  </div>
                  <BPageLoader v-if="blessingsResponse.isLoading" />
                  <div
                    class="
                      flex flex-grow flex-col
                      justify-center
                      items-center
                      py-6
                      border-t border-border-primary
                      my-3
                    "
                    v-else-if="!blessingsResponse.data.length"
                  >
                    <span
                      class="
                        h-24
                        w-24
                        rounded-full
                        bg-bg-alternate-3
                        flex
                        items-center
                        justify-center
                        my-6
                      "
                    >
                      <CashIcon
                        class="h-8 stroke-2 stroke-current"
                        style="stroke: #314b63"
                      />
                    </span>
                    <h4 class="my-4 text-lg font-semibold">
                      No Blessings Found
                    </h4>
                  </div>
                  <ul class="pt-2" v-else>
                    <li
                      v-for="blessing in blessingsResponse.data"
                      :key="blessing.id"
                      class="
                        border-t border-border-primary
                        py-2
                        flex
                        items-center
                      "
                      @click="toggleShowModal(blessing)"
                    >
                      <span
                        class="
                          rounded-full
                          border border-border-primary
                          font-bold
                          bg-bg-alternate-3
                          text-sm
                          h-8
                          w-8
                          flex flex-shrink-0
                          justify-center
                          items-center
                        "
                        :style="{
                          backgroundColor: randomColor({
                            luminosity: 'light',
                            seed: `${
                              blessing.anonymous ? 'A' : blessing.firstname[0]
                            }`,
                            format: 'rgba',
                            alpha: 0.3,
                          }),
                          color: randomColor({
                            luminosity: 'bright',
                            seed: `${
                              blessing.anonymous ? 'A' : blessing.firstname[0]
                            }`,
                          }),
                        }"
                      >
                        <img
                          class="rounded-full"
                          v-if="
                            blessing.userdetail?.account.accounttype ===
                            'CORPORATE'
                          "
                          :src="blessing.userdetail.profilePhoto"
                          alt="profile_image"
                        />
                        <span v-else>
                          {{ blessing.anonymous ? 'A' : blessing.firstname[0] }}
                        </span>
                      </span>
                      <div class="ml-4 w-full">
                        <h4 class="flex justify-between text-sm py-1">
                          <span
                            class="font-semibold flex items-center"
                            v-if="
                              blessing.userdetail?.account.accounttype ===
                              'CORPORATE'
                            "
                          >
                            {{ blessing.userdetail?.username }}
                            <VerifiedIcon
                              class="h-3 pl-1 -mt-px flex-shrink-0"
                              v-if="
                                blessing.userdetail?.verificationStatus ===
                                'VERIFIED'
                              "
                            />
                          </span>
                          <span
                            class="font-semibold"
                            v-else-if="blessing.anonymous"
                            >Anonymous</span
                          >
                          <span class="font-semibold" v-else>
                            {{ blessing.firstname }} {{ blessing.lastname }}
                            <span v-if="blessing.wishlist">
          (via
          {{ blessing.wishlist?.firstname }} {{ blessing.wishlist?.lastname }})
        </span>
        <span v-else-if="blessing.fundraiserpromotion">
          (via
          {{ blessing.fundraiserpromotion?.firstname }}
          {{ blessing.fundraiserpromotion?.lastname }})
        </span>
                          </span>
                          <span class="font-bold text-text-alternate-12">
                            ₦{{ blessing.amount.toLocaleString() }}
                          </span>
                        </h4>
                        <p
                          class="
                            flex
                            justify-between
                            text-2xs text-text-alternate-7
                            font-medium
                            py-1
                          "
                        >
                          <span class="mr-2">
                            {{
                              dayjs(blessing.createdAt).format('DD MMM YYYY')
                            }}
                          </span>
                          <span>
                            {{ dayjs(blessing.createdAt).format('HH:mm:ss') }}
                          </span>
                          <span class="ml-auto capitalize">
                            via
                            {{
                              blessing.transaction.paymentMethod.toLowerCase()
                            }}
                          </span>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="px-6" v-else-if="active === 1">
                <BPageLoader v-if="withdrawalsResponse.isLoading" />
                <div
                  class="
                    flex flex-grow flex-col
                    justify-center
                    items-center
                    py-6
                  "
                  v-else-if="!withdrawalsResponse.data.length"
                >
                  <span
                    class="
                      h-24
                      w-24
                      rounded-full
                      bg-bg-alternate-3
                      flex
                      items-center
                      justify-center
                      my-6
                    "
                  >
                    <UpdateIcon
                      class="h-8 fill-current"
                      style="fill: #314b63"
                    />
                  </span>
                  <h4 class="my-4 text-lg font-semibold">No Withdrawals</h4>
                  <p class="text-xs font-medium text-center">
                    Every withdrawal made on this project will appear here.
                  </p>
                </div>
                <div class="text-text-alternate-1" v-else>
                  <p class="flex justify-between font-semibold my-4">
                    <span class="text-lg">History</span>
                  </p>
                  <ul>
                    <li
                      v-for="withdrawal in withdrawalsResponse.data"
                      :key="withdrawal.id"
                      class="
                        border-t border-border-primary
                        py-2
                        flex
                        items-center
                      "
                    >
                      <span
                        class="
                          rounded-full
                          font-bold
                          bg-bg-alternate-3
                          text-sm
                          h-8
                          w-8
                          flex flex-shrink-0
                          justify-center
                          items-center
                        "
                        :style="{
                          backgroundColor: randomColor({
                            luminosity: 'light',
                            seed: `${authUser.firstname[0]}`,
                            format: 'rgba',
                            alpha: 0.3,
                          }),
                          color: randomColor({
                            luminosity: 'bright',
                            seed: `${authUser.firstname[0]}`,
                          }),
                        }"
                      >
                        {{ authUser.firstname[0] }}
                      </span>
                      <div class="ml-4 w-full">
                        <h4 class="flex justify-between text-sm py-1">
                          <span class="font-semibold">Withdrawal</span>
                          <span class="font-bold text-text-alternate-14">
                            - ₦{{ withdrawal.amount.toLocaleString() }}
                          </span>
                        </h4>
                        <p
                          class="
                            flex
                            justify-between
                            text-2xs text-text-alternate-7
                            font-medium
                            py-1
                          "
                        >
                          <span class="flex">
                            <span class="mr-2">
                              {{
                                dayjs(withdrawal.createdAt).format(
                                  'DD MMM YYYY'
                                )
                              }}
                            </span>
                            <span>
                              {{
                                dayjs(withdrawal.createdAt).format('HH:mm:ss')
                              }}
                            </span>
                          </span>
                          <span
                            class="font-semibold"
                            :class="
                              withdrawal.status == 'PAID'
                                ? 'text-text-alternate-6'
                                : withdrawal.status == 'REJECTED' ||
                                  withdrawal.status == 'UNSUCCESFUL'
                                ? 'text-text-danger'
                                : withdrawal.status == 'PENDING'
                                ? 'text-text-alternate-15'
                                : ''
                            "
                          >
                            {{ withdrawal.status }}</span
                          >
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="px-6" v-else-if="active === 2">
                <BPageLoader v-if="pledgesResponse.isLoading" />
                <div
                  class="
                    flex flex-grow flex-col
                    justify-center
                    items-center
                    py-6
                  "
                  v-else-if="!pledgesResponse.data.length"
                >
                  <span
                    class="
                      h-24
                      w-24
                      rounded-full
                      bg-bg-alternate-3
                      flex
                      items-center
                      justify-center
                      my-6
                    "
                  >
                    <CashIcon
                      class="h-8 stroke-2 stroke-current"
                      style="stroke: #314b63"
                    />
                  </span>
                  <h4 class="my-4 text-lg font-semibold">No Pledges</h4>
                  <p class="text-xs font-medium text-center">
                    Once you start receiving pledges, they will appear here.
                  </p>
                </div>
                <ul class="pt-2 flex flex-col" v-else>
                  <li
                    class="border-b border-border-primary py-2 cursor-pointer"
                    v-for="pledge in pledgesResponse.data"
                    :key="pledge.id"
                    @click="showPledgers(pledge)"
                    :style="{ order: pledgeOrders[pledge.donationtype] }"
                  >
                    <div class="py-1">
                      <h3
                        class="
                          flex
                          justify-between
                          text-2xs text-text-alternate-7
                          font-medium
                          py-0.5
                        "
                      >
                        <span>Plan Name</span><span>Amount</span>
                      </h3>
                      <div
                        class="
                          flex
                          justify-between
                          text-sm
                          font-semibold
                          text-text-alternate-1
                          py-0.5
                          capitalize
                        "
                      >
                        <p>{{ pledge.donationtype.toLowerCase() }}</p>
                        <p>
                          <span>₦{{ pledge.amount.toLocaleString() }}</span
                          >/<span class="capitalize">{{
                            pledge.interval
                          }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="py-1">
                      <h3
                        class="
                          flex
                          justify-between
                          text-2xs text-text-alternate-7
                          font-medium
                          py-0.5
                        "
                      >
                        <span>Total Pledgers</span><span>Total Revenue</span>
                      </h3>
                      <p
                        class="
                          flex
                          justify-between
                          text-sm
                          font-semibold
                          text-text-alternate-1
                          py-0.5
                        "
                      >
                        <span>{{ pledge.totalPledgers.toLocaleString() }}</span>
                        <span>₦{{ pledge.totalRevenue.toLocaleString() }}</span>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  </div>

  <BModal :isOpen="openState" @close="openState = false">
    <ul
      class="
        w-full
        bg-bg-secondary
        text-sm
        left-0
        rounded
        max-h-60
        text-text-primary
        font-semibold
        overflow-y-auto
      "
    >
      <li
        :class="[
          'py-2 my-2 xs:my-3 w-full cursor-pointer relative hover:bg-bg-alternate-5 capitalize',
        ]"
        v-for="(item, index) in options"
        :key="`${item}-${index}`"
        @click="handleSelect(item)"
      >
        <TickIcon
          v-if="selected === item"
          class="absolute right-0 h-5 fill-current"
          style="fill: #037ed8"
        />
        {{ item }}
      </li>
    </ul>
  </BModal>

  <!-- <BModal isOpen="withdrawModal" @close="withdrawModal = false"> </BModal> -->

  <BModal :isOpen="showModal" @close="showModal = false">
    <div class="py-2.5">
      <header class="border-b border-border-primary pb-2">
        <h2 class="flex justify-between text-sm">
          <span class="text-text-alternate-1 font-semibold">
            {{ detail.firstname }} {{ detail.lastname }}
          </span>
          <span class="font-bold" style="color: #017622">
            ₦{{ detail.amount.toLocaleString() }}
          </span>
        </h2>
        <div class="flex justify-between items-center">
          <div class="text-text-alternate-7 font-medium">
            <span class="text-2xs mr-2">
              {{ dayjs(detail.createdAt).format('DD MMM YYYY') }}
            </span>
            <span class="text-2xs">
              {{ dayjs(detail.createdAt).format('HH:mm:ss') }}
            </span>
          </div>
        </div>
      </header>
      <div class="text-2xs pt-4">
        <div class="py-2" v-if="detail.transaction.bankCode">
          <h4 class="text-text-alternate-7 font-medium">Bank</h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{ getBankName(detail.transaction.bankCode) || 'N/A' }}
          </p>
        </div>
        <div class="py-2" v-else>
          <h4 class="text-text-alternate-7 font-medium">Card Type</h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{ detail.transaction.cardType || 'N/A' }}
          </p>
        </div>
        <div class="py-2" v-if="detail.transaction.accountNumber">
          <h4 class="text-text-alternate-7 font-medium">Account Number</h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{ detail.transaction.accountNumber }}
          </p>
        </div>
        <div class="py-2" v-else>
          <h4 class="text-text-alternate-7 font-medium">Card Number</h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{
              detail.transaction.cardLast4Digits
                ? `**********${detail.transaction.cardLast4Digits}`
                : 'N/A'
            }}
          </p>
        </div>
        <div class="py-2">
          <h4 class="text-text-alternate-7 font-medium">Remarks</h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{ detail.transaction.desc || 'N/A' }}
          </p>
        </div>
        <div class="pt-2">
          <h4 class="text-text-alternate-7 font-medium">
            Transaction Reference
          </h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{ detail.transaction.transactionReference || 'N/A' }}
          </p>
        </div>
      </div>
    </div>
  </BModal>

  <BModal
    :isOpen="isWithdraw"
    @close="isWithdraw = false"
    :heading="'Withdraw from wallet'"
  >
    <BPageLoader v-if="bankResponse.isLoading" />
    <div class="flex flex-col items-center pb-6" v-else-if="!bankResponse.data">
      <span
        class="
          h-24
          w-24
          rounded-full
          bg-bg-alternate-3
          flex
          items-center
          justify-center
          my-4
        "
      >
        <BankIcon class="h-8 fill-current" style="fill: #323232" />
      </span>
      <h4 class="text-text-primary font-semibold my-4">No bank account</h4>
      <p class="text-center font-medium text-xs text-text-primary px-3">
        You have not added any withdrawal bank account. Click the button below
        to add a receiving account.
      </p>
      <router-link
        class="
          font-bold
          mx-auto
          w-full
          mt-8
          py-4
          xs:py-5
          text-sm text-center text-btn-text-primary
          bg-btn-bg-primary
          inline-block
          rounded
        "
        :to="{ name: 'Payment' }"
      >
        Add Bank Account
      </router-link>
    </div>
    <div class="flex flex-col pb-24 lg:pt-6" v-else>
      <div class="flex flex-col mt-6">
        <label class="text-text-primary text-sm font-semibold">Amount(₦)</label>
        <div
          class="
            flex
            items-center
            border
            rounded
            my-2
            overflow-hidden
            input-shadow
          "
          :class="
            showError ? 'border border-border-danger' : 'border-border-primary'
          "
        >
          <input
            class="
              py-3.5
              pl-3.5
              text-sm
              font-medium
              flex-grow
              focus:outline-none
            "
            @input="validateAmount(data.wallet.balance)"
            required
            type="number"
            :disabled="withdrawResponse.isLoading"
            v-model.number="amount"
            id="amount"
          />
          <div
            class="cursor-pointer px-3.5"
            @click="
              (amount = data.wallet.balance),
                validateAmount(data.wallet.balance)
            "
          >
            <p class="text-xs">MAX</p>
          </div>
        </div>
        <p v-if="showError" class="text-text-danger text-2xs">
          {{ showError }}
        </p>
      </div>
      <!-- <div class="flex flex-col">
        <label class="text-text-primary text-sm font-semibold">Amount(₦)</label>
        <input
          class="
            border
            rounded
            border-border-primary
            my-2
            p-3.5
            text-sm
            font-medium
            focus:outline-none
            input-shadow
          "
          @input="validateAmount(data.wallet.balance)"
          required
          type="number"
          :disabled="withdrawResponse.isLoading"
          v-model.number="amount"
        />
        <p v-if="showError" class="text-text-danger text-2xs">
          You cannot withdraw more than ₦{{
            data.wallet.balance.toLocaleString()
          }}
        </p>
      </div> -->
      <div class="mt-3">
        <p class="text-text-primary text-sm font-semibold">Receiving Account</p>
        <div
          class="border rounded border-border-primary p-4 text-xs flex mt-2.5"
        >
          <BankIcon class="h-6 fill-current" style="fill: #323232" />
          <div class="flex flex-col justify-between px-3 pb-1">
            <p class="font-semibold text-text-primary truncate uppercase">
              {{ bankResponse.data.accountname }}
            </p>
            <p class="text-text-alternate-3 font-semibold my-1">
              {{ bankResponse.data.accountnumber }}
            </p>
            <p class="text-text-alternate-3 font-medium uppercase">
              {{ bankResponse.data.bankname }}
            </p>
          </div>
        </div>
        <div
          class="
            px-6
            py-4
            flex
            gap-4
            z-10
            bg-bg-alternate-3
            absolute
            left-0
            w-full
            bottom-14
          "
        >
          <button
            class="
              bg-btn-bg-primary
              focus:outline-none
              text-btn-text-primary
              px-4
              py-3.5
              xs:py-4
              w-full
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            :disabled="!amount || showError || withdrawResponse.isLoading"
            @click="withdrawalFromWallet"
          >
            <BButtonLoader
              class="h-4 w-4 mx-auto"
              v-if="withdrawResponse.isLoading"
            />
            <span v-else>Continue</span>
          </button>
        </div>
      </div>
    </div>
  </BModal>

  <BModal :isOpen="showPickMenu" @close="showPickMenu = false">
    <ul class="text-text-primary font-semibold text-sm w-full">
      <li class="my-1 xs:my-3">
        <router-link
          class="
            py-2
            w-full
            inline-block
            hover:bg-bg-alternate-5
            text-sm
            font-semibold
          "
          :to="{ name: 'EditFundraiser', params: { id: data.id } }"
        >
          Edit
        </router-link>
      </li>
      <li class="my-1 xs:my-3">
        <router-link
          class="
            py-2
            w-full
            inline-block
            hover:bg-bg-alternate-5
            text-sm
            font-semibold
          "
          :to="{ name: 'fundraiserHistory' }"
        >
          Fundraiser History
        </router-link>
      </li>
      <li
        class="
          py-2
          my-1
          xs:my-3
          w-full
          cursor-pointer
          relative
          hover:bg-bg-alternate-5
          text-sm
          font-semibold
        "
      >
        Promote
      </li>
      <li
        class="
          py-2
          my-1
          xs:my-3
          w-full
          cursor-pointer
          relative
          hover:bg-bg-alternate-5
          text-sm
          font-semibold
        "
        @click="handleEmbed"
      >
        Embed
      </li>
      <li class="my-1 xs:my-3">
        <button
          class="
            py-2
            w-full
            inline-block
            hover:bg-bg-alternate-5
            text-sm
            font-semibold
            focus:outline-none
            text-left
          "
          @click="handleDelete"
        >
          Delete
        </button>
      </li>
    </ul>
  </BModal>

  <BModal :isOpen="isDelete" @close="isDelete = false">
    <div class="text-center flex flex-col items-center">
      <FailureIcon class="my-4 mx-auto" />
      <h2 class="text-lg text-text-primary font-semibold my-4">
        Delete Fundraiser?
      </h2>
      <p class="text-xs text-text-alternate-3 font-medium px-6">
        Deleting a fundraiser means that your fundraiser would be removed
        entirely from our system. All blessings received will be automatically
        transferred to your account upon verification by the admin. This action
        can’t be undone.
      </p>
      <button
        class="
          bg-btn-bg-primary
          max-w-lg
          text-btn-text-primary
          rounded
          w-full
          mt-6
          py-4
          font-bold
          text-sm
          xs:text-base
          focus:outline-none
        "
        @click="deleteThisFundraiser"
        :disabled="delResponse.isLoading"
      >
        <BButtonLoader class="h-4 w-4 mx-auto" v-if="delResponse.isLoading" />
        <span v-else>Delete</span>
      </button>
      <button
        class="
          text-text-primary
          focus:outline-none
          my-6
          rounded
          text-center text-sm
          xs:text-base
          font-bold
        "
        @click="isDelete = false"
      >
        Cancel
      </button>
    </div>
  </BModal>
  <PledgerModal
    :isOpen="isPledge"
    :pledgeType="pledgeType"
    :pledgeResponse="pledgeResponse"
    @close="isPledge = false"
  />
  <EmbedModal v-model="isEmbed" :slug="slug" />
</template>

<script>
import { ref, toRefs, computed, watch } from 'vue';
import PledgerModal from '@/components/ui/PledgerModal';
import randomColor from 'randomcolor';
import { useRoute } from 'vue-router';
import { useApi } from '@/cmp-functions/useApi';
import { banks } from '@/data/banks';
import { getCycleDays } from '@/utils';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import Swal from 'sweetalert2';
import {
  getAllBlessors,
  getBlessings,
  getFundraiserWallet,
  getWithdrawals,
  getPledge,
  getUserBank,
  deleteFundraiser,
  postWalletWithdrawal,
  getFundraiserPledges,
} from '@/services/api';
// import BarChart from './BarChart';
import BModal from '@/components/ui/BModal';
import EmbedModal from '@/components/ui/EmbedModal';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);

const obj = {
  today: Array.from({ length: 24 }, (_, i) => i),
  week: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  month: Array.from(
    { length: dayjs().endOf('month').format('DD') },
    (_, i) => i + 1
  ),
};

const options = ['today', 'week', 'month'];

const getBegAndEnd = (value) => {
  return [
    dayjs().startOf(value).format('YYYY-MM-DD HH'),
    dayjs().endOf(value).format('YYYY-MM-DD HH'),
  ];
};

const getNextEndDate = (createdAt, cycle) => {
  const now = dayjs();
  const next = dayjs(createdAt).add(getCycleDays(cycle), 'day');
  const max = dayjs.max(now, next);
  if (max === next) return next;
  return getNextEndDate(next, cycle);
};

const pledgeOrders = {
  DIAMOND: 1,
  GOLD: 2,
  SILVER: 3,
};

export default {
  name: 'AccountFundraiser',

  components: {
    // BarChart,
    BModal,
    EmbedModal,
    PledgerModal,
  },

  setup() {
    const toast = useToast();
    const tabs = ['Blessings', 'Withdrawals', 'Pledges'];
    const route = useRoute();
    const store = useStore();
    const authUser = store.state.auth.authData;
    const { id: fundraiserId } = route.params;
    const [pledgeResponse, fetchPledge] = useApi(getPledge);
    const [response, fetchFundraiserWallet] = useApi(getFundraiserWallet);
    const [blessorsResponse, fetchBlessors] = useApi(getAllBlessors);
    let [blessingsResponse, fetchBlessings] = useApi(getBlessings);
    const [withdrawalsResponse, fetchWithdrawals] = useApi(getWithdrawals);
    const [withdrawResponse, makeWithdrawals] = useApi(postWalletWithdrawal);
    const [bankResponse, fetchUserBank] = useApi(getUserBank);
    const [delResponse, removeFundraiser] = useApi(deleteFundraiser);
    const [pledgesResponse, fetchFundraiserPledges] =
      useApi(getFundraiserPledges);

    fetchFundraiserWallet({ fundraiserId, userId: authUser.id });
    fetchBlessors(fundraiserId);
    fetchBlessings({ fundraiserId });
    fetchWithdrawals(fundraiserId);
    fetchFundraiserPledges(fundraiserId);
    fetchBlessings = debounce(fetchBlessings, 250);

    const active = ref(0);
    const isPledge = ref(false);
    const pledgeType = ref(null);
    const selected = ref('today');
    const openState = ref(false);
    const showModal = ref(false);
    const showSearch = ref(false);
    const detail = ref(null);
    const searchName = ref('');
    const amount = ref('');
    const isWithdraw = ref(false);
    const showPickMenu = ref(false);
    const isDelete = ref(false);
    const isEmbed = ref(false);
    const showError = ref('');
    const successModal = ref(false);
    const isEarlyWithdrawal = ref(false);

    watch(searchName, (name) => {
      fetchBlessings({ fundraiserId, name });
    });

    const handleDelete = () => {
      isDelete.value = true;
      showPickMenu.value = false;
    };

    const showPledgers = ({ planCode, donationtype }) => {
      pledgeResponse.data = null;
      fetchPledge(response.data.id, planCode);
      isPledge.value = true;
      pledgeType.value = donationtype.toLowerCase();
    };

    const validateAmount = (balance) => {
      if (amount.value < 1000) {
        showError.value = 'Minimum withdrawable amount is ₦1000';
      } else if (amount.value > balance) {
        showError.value = `You cannot withdraw more than ₦${balance.toLocaleString()}`;
      } else {
        showError.value = '';
      }
    };
    const handleEmbed = () => {
      isEmbed.value = true;
      showPickMenu.value = false;
    };

    const slug = computed(() => {
      const { id, title } = response.data || {};

      return `${id}-${title
        ?.trim()
        ?.toLowerCase()
        ?.replace(/[^a-z\d\s:]/g, '')
        ?.replace(/\s+/g, '-')}`;
    });

    const deleteThisFundraiser = () => {
      removeFundraiser(fundraiserId);
    };

    watch(delResponse, ({ isSuccess }) => {
      if (isSuccess) isDelete.value = false;
    });

    const toggleShowModal = (index) => {
      showModal.value = !showModal.value;
      detail.value = index;
    };

    const withdrawalFromWallet = () => {
      const payload = {
        fundraiserId,
        bankId: bankResponse.data.id,
        userId: authUser.id,
        amount: amount.value,
        walletId: response.data.wallet.id,
        isEarlyWithdrawal,
      };
      makeWithdrawals(payload);
    };

    watch(withdrawResponse, ({ isSuccess }) => {
      if (isSuccess)
        (isWithdraw.value = false),
          (successModal.value = true),
          setTimeout(() => {
            successModal.value = false;
          }, 2000),
          toast.success('Withdrawal request sent!');
    });

    const getUserBankDetails = () => {
      isWithdraw.value = true;
      fetchUserBank(authUser.id);
    };

    const getBankName = (bankCode) => {
      return banks.find(({ nipCode }) => nipCode === bankCode)?.name;
    };

    const handleSelect = (value) => {
      selected.value = value;
      openState.value = false;
    };

    const computeData = (params) => {
      params = params === 'today' ? 'day' : params;
      const [start, end] = getBegAndEnd(params);
      return blessorsResponse.data?.filter(({ createdAt }) =>
        dayjs(createdAt).isBetween(start, end, null, [])
      ) || [];
    };

    const sumOfBlessing = computed(() => {
      return computeData(selected.value).reduce(
        (acc, { amount }) => acc + amount,
        0
      );
    });

    const toggleShowSearch = () => {
      showSearch.value = !showSearch.value;
    };

    const getGraphData = (params) => {
      const dataComputed = computeData(params);
      const data = {};
      dataComputed.forEach(({ createdAt, amount }) => {
        let value;
        if (params === 'week') {
          value = dayjs(createdAt).day();
        } else if (params === 'month') {
          value = dayjs(createdAt).date();
        } else if (params === 'today') {
          value = dayjs(createdAt).hour();
        }

        data[value] = (data[value] || 0) + amount;
      });

      const step = params === 'month' ? 1 : 0;

      const res = obj[params].map((_, index) => data[index + step] || 0);

      return res;
    };

    const checkWithdrawalDueDate = () => {
      const nextPayoutDate = new Date(response.data.nextPayoutDate);
      const today = new Date();
      if (today < nextPayoutDate) {
        isEarlyWithdrawal.value = true;
        Swal.fire({
          title: `<p class="text-lg">Early Withdrawal</p>`,
          text: `Your next payout date is in the future, withdrawing now will attract 5% early withdrawal charge. Do you want to continue?`,
          // icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            getUserBankDetails();
          }
        });
      } else {
        getUserBankDetails();
      }
    };

    const isOneTime = computed(
      () => response.data?.fundraiserType !== 'RECURRING'
    );

    const getTime = () => {
      const { createdAt, duration, fundraiserCycle } = response.data;
      if (isOneTime.value) {
        return dayjs(createdAt).add(duration, 'd').format('D MMM');
      }
      const nextCycleEndDate = getNextEndDate(createdAt, fundraiserCycle);
      return dayjs(nextCycleEndDate).format('D MMM');
    };

    const dataset = computed(() => ({
      labels: obj[selected.value],
      datasets: [
        {
          backgroundColor: '#57B894',
          data: getGraphData(selected.value),
        },
      ],
    }));

    return {
      tabs,
      active,
      amount,
      dayjs,
      isPledge,
      authUser,
      slug,
      getTime,
      pledgeResponse,
      searchName,
      randomColor,
      showPickMenu,
      showSearch,
      showPledgers,
      pledgeType,
      isWithdraw,
      pledgeOrders,
      isEmbed,
      delResponse,
      deleteThisFundraiser,
      toggleShowSearch,
      getUserBankDetails,
      withdrawalsResponse,
      withdrawResponse,
      pledgesResponse,
      getBankName,
      withdrawalFromWallet,
      selected,
      isDelete,
      handleDelete,
      handleEmbed,
      toggleShowModal,
      showModal,
      detail,
      blessingsResponse,
      bankResponse,
      openState,
      handleSelect,
      sumOfBlessing,
      options,
      dataset,
      ...toRefs(response),
      blessorsResponse,
      validateAmount,
      showError,
      successModal,
      checkWithdrawalDueDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-border {
  border: 4px solid transparent;
}

.nav-active {
  border-bottom-color: #577387;
}
</style>
